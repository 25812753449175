





















































































































import {Component, Vue} from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import {
  apiCategoryAdd,
  apiCategoryDetail,
  apiCategoryEdit,
  apiCategoryLists, apiGetFormSupplier,
} from '@/api/goods'

@Component({
  components: {
    MaterialSelect,
  },
})
export default class AddCategory extends Vue {
  $refs!: { form: any };
  id!: any;
  loading = false;
  hasPid = 0;
  categoryList = [];
  // 供应商列表
  supplierList = [];
  form: any = {
    name: '',
    pid: '',
    image: '',
    sort: '',
    is_show: 1,
    is_recommend: 0,
    // 供应商 id
    form_origin: '',
    // 英文标题
    title_en: '',
    // 描述
    description: ''
  }

  rules = {
    name: [
      {
        required: true,
        message: '请输入分类名称',
        trigger: ['blur', 'change'],
      },
    ],
    image: [
      {
        required: true,
        message: '请选择分类图标',
        trigger: ['blur', 'change'],
      },
    ],
  }

  handleSave() {
    this.$refs.form.validate((valid: boolean) => {
      if (valid) {
        if (!this.hasPid) {
          this.form.pid = ''
        }
        const api = this.id
            ? apiCategoryEdit(this.form)
            : apiCategoryAdd(this.form)
        api.then(() => {
          this.$router.go(-1)
        })
      } else {
        return false
      }
    })
  }

  getCategoryDetail() {
    this.loading = true
    apiCategoryDetail(this.id).then((res: any) => {
      if (res.pid) {
        this.hasPid = 1
      }
      this.form = res
      this.loading = false
    })
  }

  getCategoryList() {
    apiCategoryLists({page_type: 1}).then((res: any) => {
      res?.lists.forEach((item: any) => {
        item.sons &&
        item.sons.forEach((sitem: any) => {
          delete sitem.sons
        })
      })
      this.categoryList = res?.lists
    })
  }

  // 获取供应商列表
  async getFormSupplier() {
    this.supplierList = await apiGetFormSupplier();
  }

  created() {
    this.getFormSupplier();
    this.id = this.$route.query.id
    this.id && this.getCategoryDetail()
    this.getCategoryList()
  }
}
